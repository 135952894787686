.statusbar-company-wrapper.statusbar-company-offset {
    height: 68px;
}
.statusbar-company-wrapper {
    margin-top: 3px;
    height: 34px;
    position: fixed;
    width: 100%;
    bottom: 0;
    background-color: rgb(245, 245, 245);
}

.statusbar-company {
    font-size: 13px;
    height: 34px;
    line-height: 34px;
    border-top: 3px solid #004785;
    display: flex;
    flex-direction: row
}

.statusbar-company-tab {
    border-right: 1px solid #ddd;
    padding: 0 10px;
    color: rgb(85, 85, 85)
}

.statusbar-company-tab a {
    text-decoration: none;
    color: rgb(85, 85, 85);
}

.statusbar-company-tab:last-child {
    align-self: flex-end;
    flex: 1;
    text-align: right;
}

.statusbar-company-tab:last-child a {
    font-size: 125%;
    font-weight: bold;
}

.statusbar-company-stats {
    position: relative;
}
.statusbar-company-stats-button:hover {
    cursor: pointer;
}
.statusbar-company-stats-wrap {
    min-width: 700px;
    max-height: 400px;
    overflow: auto;
    position: absolute;
    bottom: 40px;
    background: white;
    border: 2px solid #004785;
    display: none;
    line-height: normal;
    z-index: 5;
    padding: 10px;
    margin-left: -200px;
}
.statusbar-company-stats-wrap table {
    width: 100%;
    color: #374151;
    font-size: 13px;
}
.statusbar-company-stats-wrap table th {
    border-bottom: 2px solid #374151;
}
.statusbar-company-stats-wrap table td, .statusbar-company-stats-wrap table th {
    text-align: center;
    padding: 0 4px;
}
.statusbar-company-stats-wrap table tr:nth-child(even) {
    background: #F3F4F6;
}
.statusbar-company-stats-wrap table td:first-child {
    text-align: left;
}
