@import 'statusbar.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply text-sm;
        font-family: Trebuchet ms, sans-serif;
    }
}

@layer components {
    .btn {
        @apply font-bold leading-7 border-0 px-4 text-xs;
        border-radius: 5px;
    }
    a.btn {
        @apply py-2;
    }

    .btn-gray {
        @apply text-blue bg-gray;
    }
    .btn-blue {
        @apply bg-blue text-white;
    }
    .btn-green {
        @apply bg-green text-white;
    }
    .btn-yellow {
        @apply bg-yellow text-blue;
    }
    .btn-yellow:hover {
        color: #000;
    }

    .navmenu {
        @apply hidden;
    }
    .navbar {
        background: rgb(0,39,72);
        background: linear-gradient(0deg, rgba(0,39,72,1) 0%, rgba(1,61,112,1) 30%, rgba(3,74,136,1) 55%);
        line-height: 34px;
        height: 34px;
        margin-top: 15px;
        margin-bottom: 15px;
        border-radius: 0 0 10px 10px;
        font-size: 16px;
        letter-spacing: .5px;
        padding: 0 20px;
    }
    .navbar li {
        padding-right: 20px;
    }
    .navbar li:before {
        padding-right: 20px;
        content: "|";
    }
    .navbar li:first-child:before {
        content: "";
    }
    .navbar, .navbar a {
        @apply text-white;
    }

    .pagination ul {
        @apply flex mt-3;
    }
    .pagination ul li {
        @apply px-1;
    }
    .pagination ul li.active {
        @apply text-blue;
    }
    .pagination ul li a:hover {
        @apply text-blue;
    }

    .has_error {
        color: #B91C1C;
    }

    .adn-logo {
        width: 218px;
        height: 97px;
    }
}

@layer utilities {
    .container {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    header {
    }

    header .logo {
        height: 97px;
    }

    header .search {
        @apply mx-5 flex-1 px-6 py-3 flex flex-col justify-between;
        background-color: #7cc242;
        font-family: "Georgia regular", serif;
        font-size: 20px;
    }

    header .search input {
        @apply text-sm px-2;
        border: 1px solid #90909e;
        line-height: 30px;
        width: 300px;
    }

    header .extended-search-de {
        background-image: url("/images/regionsearch-de.png");
    }

    header .extended-search-at {
        background-image: url("/images/regionsearch-at.png");
    }

    header .extended-search-ch {
        background-image: url("/images/regionsearch-ch.png");
    }

    header .extended-search-de, header .extended-search-at, header .extended-search-ch {
        @apply relative;
        background-repeat: no-repeat;
        width: 218px;
        height: 97px;
    }

    header .extended-search-de-compact {
        background-image: url("/images/regionsearch-de-compact.png");
    }
    header .extended-search-at-compact {
        background-image: url("/images/regionsearch-at-compact.png");
    }
    header .extended-search-ch-compact {
        background-image: url("/images/regionsearch-ch-compact.png");
    }

    header .extended-search-de-compact, header .extended-search-at-compact, header .extended-search-ch-compact {
        @apply relative;
        background-repeat: no-repeat;
        width: 218px;
        height: 97px;
    }

    .extended-search-smallform {
        @apply flex;
    }
    .extended-search-smallform input {
        @apply mb-2 px-1;
        max-width: 125px;
        border: 1px solid #90909e;
    }
    .extended-search-smallform .searcharrow {
        margin-left: 10px;
        margin-right: 5px;
    }

    header .searchform {
        @apply flex-1;
    }

    header .extended-search-collapse {
        @apply absolute bg-yellow px-2 pt-1 pb-2 text-sm;
    }

    .slide {
        @apply flex;
        grid-template-columns: 612px 1fr;
        grid-gap: 5px;
    }

    .slide.hidden {
        display: none;
    }

    .slide-content {
        @apply relative;
        height: 331px;
        width: 612px;
        background-repeat: no-repeat;
        border-radius: 10px 0 0 10px;
    }

    .slide-overlay {
        @apply absolute w-full text-white;
        border-bottom-left-radius: 10px;
        background-color: rgba(0, 43, 87, .8);
        height: 70px;
        bottom: 0;
        font-family: "Georgia regular", serif;
        letter-spacing: 1px;
        line-height: 28px;
        font-size: 28px;
        padding: 10px 20px;
    }

    .slide-links {
        @apply border border-gray-border;
        border-radius: 0 10px 10px 0;
        width: 315px;
    }

    .slide-links ul {
        @apply flex flex-col h-full;
    }

    .slide-links ul li {
        @apply border-b border-gray-border bg-gray-light;
        height: 20%;
    }

    .slide-links ul li:last-child {
        @apply border-b-0;
    }

    .slide-links ul li a {
        @apply h-full flex flex-col justify-center px-3;
    }

    .slide-links strong {
        @apply block text-blue no-underline overflow-hidden font-normal;
        width: auto;
        white-space: nowrap;
        font-size: 20px;
        text-overflow: ellipsis;
    }

    .slide-links span {
        color: #000;
        font-size: 12px;
        display: block;
    }

    .slide .slide-pagination {
        @apply absolute flex bottom-0 right-0 p-2;
    }

    .slide .slide-pagination-page {
        background-color: #a6a6a7;
        border: 1px solid #000;
        height: 14px;
        width: 12px;
        margin-left: 4px;
    }

    .slide .slide-pagination-page.active {
        @apply bg-yellow;
    }

    .category-abc {
        @apply flex justify-between bg-gray px-4 py-1 my-6 items-center;
    }

    .category-abc span {
        @apply text-blue;
        font-family: "Georgia regular", serif;
        font-size: 20px;
    }

    .category-abc ul {
        @apply flex;
    }

    .category-abc ul li {
        margin: 0 6px;
    }

    .category-abc ul li a {
        padding: 0 0 0 4px;
    }

    .header {
        @apply text-white overflow-hidden truncate;
        border-radius: 6px 6px 0 0;
        font-family: "Georgia regular", serif;
        font-size: 20px;
        height: 34px;
        letter-spacing: .5px;
        line-height: 35px;
        padding: 0 15px;
        white-space: nowrap;
    }

    .header-green {
        background: rgb(80, 150, 17);
        background: linear-gradient(0deg, rgba(80, 150, 17, 1) 0%, rgba(124, 194, 16, 1) 79%);
    }

    .header-blue {
        background: #00407B;
    }

    .partnerlogo {
        @apply py-2;
    }


    footer {
        @apply border-t-4 border-blue;
    }

    footer div {
        @apply bg-blue text-xs text-white mt-1 px-5 pt-4 pb-2;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    footer div ul {
        @apply flex;
    }

    footer div ul li:before {
        padding-left: 10px;
        padding-right: 10px;
        content: "|";
    }

    footer div ul li:first-child:before {
        content: "";
    }

    .index-customer {
        @apply w-1/2 pr-1 truncate;
    }

    .index-customer:nth-child(even) {
        @apply pl-1 pr-0;
    }

    .line-wrapper {
        width: 100%;
        position: relative;
        margin-bottom: 20px;
    }

    .line-wrapper .line {
        position: absolute;
        background: #ccc;
        height: 1px;
        width: 100%;
        top: 9px;
        z-index: 1;
    }

    .line-wrapper .wordwrapper {
        z-index: 2;
        background: #fff;
        position: absolute;
        right: 10px;
        padding: 0 3px;
    }

    .line-wrapper .word {
        text-align: right;
        font-size: 11px;
        color: #ccc;
        z-index: 2;
    }

    .input-searchform, .select-searchform {
        @apply bg-gray px-2 py-1;
    }

    .product {
        @apply text-sm;
        min-height: 229px;
        padding: 10px 10px 10px 241px;
        background-repeat: no-repeat;
    }

    .product-vermelding { background-image: url("/images/vermelding.png"); }
    .product-google-places { background-image: url("/images/places.png"); }
    .product-adwords { background-image: url("/images/adwords.png"); }
    .product-website { background-image: url("/images/websites.png"); }

    .form .form-element {
        @apply flex justify-between items-center pt-1;
    }
    .form .form-element.label-top {
        @apply items-start;
    }
    .form .form-element label {
        @apply text-sm w-1/3;
    }
    .form .form-element input[type="text"], .form input[type="email"] {
        @apply bg-gray text-sm px-2 py-1 flex-1;
    }
    .form .form-element textarea {
        @apply bg-gray text-sm px-2 py-1 flex-1 h-24;
    }

    .contact {
        @apply text-lg;
        background-repeat: no-repeat;
        font-family: "Georgia regular", serif;
        line-height: 30px;
        padding: 3px 0 3px 55px;
    }
    .contact-phone {
        background-image: url("/images/icons/telefon_big.png");
    }
    .contact-fax {
        background-image: url("/images/icons/fax_big.png");
    }
    .contact-email {
        background-image: url("/images/icons/mail_icon.png");
    }

    .company {
        @apply border-b border-blue;
    }
    .company:hover {
        @apply bg-green-light;
    }
    .searchresult-company {
        min-height: 95px;
    }
    .searchresult-company-highlight {
        @apply bg-green-light;
    }

    .company-title {
        @apply font-bold text-blue underline;
        background-image: url("/images/icons/marker_red_small.png");
        background-repeat: no-repeat;
        padding-left: 20px;
    }

    .googlemaps {
        @apply bg-gray;
        height: 600px;
    }
    .googlemaps-details {
        height: 300px;
    }

    #map {
        height: inherit;
    }
    .no-map {
        @apply flex justify-center pt-6;
        height: inherit;
    }
    .no-map a {
        @apply underline;
    }
    .googlemaps-details .no-map {
        @apply items-center pt-0;
    }
    .LoadGoogleMaps {
        @apply underline;
    }

    .requestGoogleMapsLoad {
        color: red !important;
    }
    .LoadGoogleMapsImage {
        @apply flex flex-col justify-center p-4 text-blue font-semibold ;
        background-repeat: no-repeat;
        height: 200px;
        width: 200px;
    }
    .LoadGoogleMapsImage span {
        @apply mt-2;
    }
    .LoadGoogleMapsImage-de {
        background-image: url("/images/empty-map-de.png");
    }
    .LoadGoogleMapsImage-at {
        background-image: url("/images/empty-map-at.png");
    }
    .LoadGoogleMapsImage-ch {
        background-image: url("/images/empty-map-ch.png");
    }

    .company-abc {
        @apply flex flex-wrap;
    }
    .company-abc li {
        @apply flex justify-center items-center;
        border: 1px solid #aaa;
        height: 20px;
        width: 20px;
        margin: 8px 4px 0 0;
    }
    .company-abc li a {
        color: #777;
    }

    .company-naw,
    .company-phone,
    .company-mobile,
    .company-fax,
    .company-email,
    .company-website
    {
        @apply mb-1;
        background-repeat: no-repeat;
        padding-left: 30px;
        line-height: 22px;
        word-wrap: break-word;
    }
    .company-naw { background-image: url("/images/icons/card.png"); }
    .company-phone { background-image: url("/images/icons/telefoon.png"); }
    .company-mobile { background-image: url("/images/icons/mobiel.png"); }
    .company-fax { background-image: url("/images/icons/fax.png"); }
    .company-email { background-image: url("/images/icons/email.png"); }
    .company-website { background-image: url("/images/icons/website.png"); }

    .company-whatsapp a img {
        max-width: 153px;
    }
    .company-contactform a {
        border-radius: 6px;
    }

    .socials {
        @apply flex flex-wrap mt-4;
    }
    .socials svg {
        @apply text-blue h-7 w-7 mr-4 my-2;
    }
    .socials svg:hover {
        @apply text-yellow;
    }

    /* cities */
    #cities .page {
        @apply mx-auto my-4;
        width: 100%;
        max-width: 962px;
        font-family: serif;
    }

    #cities .prose-sm h1, h2, h3, h4, h5, h6 {
        @apply text-blue font-bold;
        margin-bottom: 0.4em;
    }

    #cities .prose-sm h2 {
        @apply bg-green text-white font-normal overflow-hidden truncate;
        border-radius: 6px 0 0 0;
        font-size: 20px;
        height: 34px;
        letter-spacing: .5px;
        line-height: 35px;
        padding: 0 15px;
        white-space: nowrap;
    }

    #cities header > div {
        background-repeat: no-repeat;
        height: 209px;
    }
    #cities header.header-be > div {
        background-image: url("/images/cities/be/city.png");
    }
    #cities header.header-nl > div {
        background-image: url("/images/cities/nl/city.png");
    }

    #cities header input {
        @apply px-2;
        width: 450px;
        line-height: 28px;
        border: 1px solid #ccc;
        background: rgb(204,204,204);
        background: linear-gradient(90deg, rgba(204,204,204,1) 0%, rgba(255,255,255,1) 1%);
    }

    #cities .cityinfo {
        @apply text-blue;
    }

    #cities .cityinfo strong {
        @apply font-semibold;
    }

    #cities aside ul.list-square {
        margin-left: 1rem;
    }

    #cities footer div {
        background-color: transparent;
        @apply mt-0 px-0 pt-0 pb-0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    #cities footer .footer-categories {
        @apply flex bg-blue-light px-2 py-4;
    }
    #cities footer .footer-categories div a {
        @apply text-blue leading-loose;
    }
    #cities footer .footer-categories div {
        @apply px-4;
    }
    #cities footer .copy {
        @apply bg-blue text-xs text-white mt-1 px-5 py-2;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .category-page article {
        @apply bg-gray p-4;
    }
    .category-page article > div {
        @apply overflow-auto;
        height: 270px;
    }
    .category-page .header h2, .category-page .header h3 {
        @apply text-white font-normal;
    }
    .category-page .header.header-blue {
        @apply text-base flex items-center;
    }
    .category-page .pagination {
        @apply flex mt-2;
    }
    .category-page .pagination li a {
        @apply underline;
    }
    .category-page .pagination li {
        @apply pr-2;
    }
    .category-page article a {
        @apply underline;
    }
    .hideGoogleMaps {
        @apply hidden text-xs mt-1;
    }
    .flashmessage {
        @apply flex items-center mb-4 px-3 py-2 font-semibold bg-yellow text-blue rounded;
        min-width: 100px;
    }
    .flashmessage.hidden {
        display: none;
    }
    .flashmessage.notify_success {
        @apply bg-yellow text-white;
    }
    .flashmessage.notify_failure {
        @apply bg-red text-white;
    }

    .only-responsive {
        @apply hidden;
    }

    .company-by-phone {
        @apply border-blue mt-2 pb-2;
        background-image: url("/images/icons/telefon_big.png");
        background-repeat: no-repeat;
        padding-left: 40px;
        border-bottom-width: 1px;
        border-bottom-style: solid;
    }
    .company-by-phone-nr {
        @apply font-bold text-lg;
    }
    .company-by-phone-title {
        @apply text-blue font-bold;
    }
}

@media (max-width: 950px) {
    .only-responsive {
        @apply block;
    }
    .slide {
        display: block
    }
    .slide-content {
        width: 100%;
        border-radius: 0;
    }
    .slide-overlay {
        font-size: 13px;
        border-radius: 0;
    }
    .slide-links {
        @apply mt-2 w-full;
        border-radius: 0;
    }
    .slide-links li {
        @apply py-2;
    }

    .category-abc {
        display: block;
    }
    .category-abc ul {
        @apply flex-wrap;
    }

    .two-columns {
        @apply flex-col px-3 pt-4;
    }
    .two-columns > .w-1\/2 {
        @apply w-full;
    }
    .two-columns .header {
        @apply mt-8;
    }

    footer div {
        border-radius: 0;
    }
    footer div ul {
        @apply flex-col;
    }
    footer div ul li {
        @apply py-1;
    }
    footer div ul li::before {
        content: '';
    }

    nav .navbar {
        @apply flex flex-col justify-center mb-0;
        border-radius: 0;
        min-height: 34px;
        height: auto;
    }
    nav .navbar ul {
        @apply flex-col;
        display: none;
    }
    nav .navbar ul li::before {
        content: '';
    }
    .navmenu {
        @apply block;
    }
    .navmenu svg {
        @apply cursor-pointer;
    }
    nav .navbar ul.showmenu {
        @apply flex;
    }

    header .search {
        @apply m-0;
    }
    header .search .text-blue {
        font-size: 16px;
    }
    .extended-search {
        @apply hidden;
    }
    header > div.flex {
        @apply block;
    }
    header .logo {
        @apply flex mt-1 mb-2 ml-4 justify-center;
        max-width: 100px;
        height: auto;
    }
    header .logo .uwa-logo {
        height: auto;
    }
    header .search input {
        width: auto;
    }

    .container {
        @apply my-0;
    }

    .googlemaps {
        height: 300px;
    }

    .product {
        padding: 10px 10px 10px 150px;
        background-size: 40%;
    }
    .products .pr-3 {
        @apply pr-0;
    }
    .products .pl-3 {
        @apply pl-0;
    }

    #cities footer .footer-categories {
        display: block;
    }
    #cities header input {
        width: 35%;
    }
    #cities header > div.flex {
        display: flex;
    }
    #cities form {
        @apply pr-2;
        text-align: right;
    }

    .categorylist .md\:w-1\/2 {
        @apply leading-loose w-full;
    }

    .category-page {
        @apply mx-2 mt-4;
    }
}
